<template>
  <b-modal
    id="import-tiss-xml-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
    @show="getImportTissXML"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">Importar XML de resposta da operadora</p>
      <Close class="icon" @click="closeModal" />
    </div>

    <div class="modal-body p-3">
      <b-row class="mt-3" v-if="selectedFiles.length === 0">
        <b-col>
          <p class="mb-3">
            Importe o arquivo XML de retorno enviado pelo convênio para gerenciar as guias de modo automático.
          </p>
        </b-col>
      </b-row>

      <b-row v-if="selectedFiles.length === 0 && !wasImported">
        <b-col class="file-content">
          <div class="set-file" @drop="addFiles" @dragover.prevent @drop.prevent>
            <div class="set-file-content">
              <p class="set-file-content-text">Arraste e solte os arquivos aqui</p>
              <div class="middle-set-file-content">
                <div class="middle-set-file-content-detail"></div>
                <p class="middle-set-file-content-text">ou</p>
                <div class="middle-set-file-content-detail"></div>
              </div>
              <label
                for="set-file-content-input"
                class="set-file-content-input"
              >
                Anexar arquivos
              </label>
              <input
                id="set-file-content-input"
                name="set-file-content-input"
                type="file"
                accept=".xml"
                @change="addFiles"
              >
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="selectedFiles.length > 0 && !wasImported">
        <b-col class="file-content">
          <div class="card card-selected-files m-3" v-for="(file,index) in selectedFiles" :key="index">
            <div class="card-body p-0">
              <div class="d-flex justify-content-between align-items-end">
                <DocIcon
                  class="icon m-2"
                />
                <div class="m-2 w-100">
                  <div>
                    {{ file.custom_name }}
                  </div>
                  <span class="text-muted position-relative bottom-0">{{ file.size }}</span>
                </div>
                <Trash
                  class="icon-trash m-2"
                  @click="removeFile(index)"
                />
              </div>
            </div>
          </div>

        </b-col>
      </b-row>

      <b-row v-if="wasImported">
        <b-col class="file-content">
          <div class="card m-3">
            <div class="card-body p-0">
              <div class="card-text">
                <div class="icon-loader">
                    <LoaderProgress/>
                </div>
                <div class="send-files">
                  Carregando...
                </div>
                Olá, recebemos sua solicitação. O(s) arquivo(s) XML será(ão) carregado(s) e, <strong>em breve, estará(ão) disponível(is)</strong>. Você pode <strong>utilizar o Eyecare BI normalmente ou desligar o computador</strong> enquanto o XML é processado. <strong>Avisaremos quando estiver pronto!</strong>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="selectedFiles.length > 0 && !wasImported">
        <b-col>
          <div class="text-center">
            <b-button
              class="ml-2 text-red"
              v-if="selectedFiles.length > 0"
              variant="link"
              @click="clearSelectedFile"
            >
              Cancelar
            </b-button>
            <b-button
              v-if="selectedFiles.length > 0"
              class="ml-2"
              size="lg"
              variant="primary"
              @click="uploadFile"
            >
              Enviar arquivo
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <div class="modal-footer">
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="closeModal">
          {{ labelClose }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import DocIcon from '@/assets/icons/doc.svg'
import Trash from '@/assets/icons/trash.svg'
import LoaderProgress from '@/assets/icons/loader-progress.svg'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'

export default {
  name: 'ImportTissXMLModal',
  components: {
    Close,
    Trash,
    DocIcon,
    LoaderProgress
  },
  props: {
    getBatches: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      file: null,
      selectedFiles: [],
      imports: [],
      wasImported: false,
      labelClose: 'Fechar'
    }
  },
  methods: {
    async getImportTissXML() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getImportTissXML(this.clinic.id)
        this.imports = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeModal(ev) {
      this.clearSelectedFile(ev)
      this.imports = []
      if (this.wasImported) {
        this.getBatches()
      }
      this.wasImported = false
      this.$bvModal.hide('import-tiss-xml-modal')
    },
    dragFile(e) {
      e.preventDefault()
      e.stopPropagation()
      this.file = e.dataTransfer.files[0]
    },
    clearSelectedFile(ev) {
      this.selectedFiles = [];
    },
    onFileSelect(e) {
      this.file = e.target.files[0]
    },
    async uploadFile(ev) {
      ev.stopPropagation()
      
      const formData = new FormData()
      
      formData.append('clinic_id', this.clinic.id)
      formData.append('user', this.user.id);
      formData.append('tabId', window.sessionStorage.getItem('tabId'));

      this.selectedFiles.forEach((file, index) => {
        formData.append('files[]', file.file, file.custom_name);
      });

      // const isLoading = this.$loading.show()
      
      this.labelClose = 'Concluir';
      
      try {
        this.wasImported = true;
        const response = await this.api.importTissBatchXML(this.clinic.id, formData)
        this.clearSelectedFile(ev)
        this.imports.unshift(response.data)
      } catch (error) {
        const message = error.response.data.message
        if (
          [
            'Arquivo inválido',
            'XML inválido',
            'Lote não encontrado',
            'Item não encontrado'
          ].includes(message)
        ) {
          this.$toast.error(message)
          return
        }
        this.$toast.error(
          'Não foi possível realizar essa ação, caso o problema persista, contate o suporte.'
        )
        this.labelClose = 'Fechar';
        this.wasImported = false;
      }
    },
    addFiles(e) {
      const forms = []
      const files = e.target.files || e.dataTransfer.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const type = file.type.split('/')[1]
        let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
        nameExt = nameExt.length > 1 ? nameExt[1] : '';
        
        if (!['xml'].includes(type || nameExt)) {
          return this.$toast.error('Formato de arquivo não suportado!');
        }
        
        forms.push({
          file,
          type,
          custom_name: file.name,
          size: (Math.round(file.size / 1024 * 100) / 100) + "Kb",
          error: false,
        });

      }
      this.selectedFiles = forms
    },
    removeFile(index){
      this.selectedFiles.splice(index, 1);
    }
  }
}
</script>

<style lang="scss">
#import-tiss-xml-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
    border-radius: 8px;

    .modal-body {
      font-family: Nunito Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;

      .card {
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        .icon-loader {
          text-align: center;
          animation: girar 2s linear infinite;
        }

        @keyframes girar {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }

        .send-files {
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          line-height: 31.68px;
          margin-bottom: 20px;
          color: #0C1D59;
        }
      }
      
      p {
        color: #525C7A;
      }

      .icon-trash {
        height: auto;
        width: 32px;
        stroke: #A4B1DF;
      }

      .text-red {
        color: var(--content-alert-danger, #F63220);
      }

      .file-content {
        padding: 30px;
        border-radius: 8px;
        border: 1px dotted #C6CEEB;
        background: #F4F5FB;
        margin-bottom: 20px;
      }
      
      .area {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75%;
        min-height: 32vh;
        border: 1px dashed var(--type-placeholder);
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--neutral-100);
        margin-bottom: 4%;
      }

      .file-name {
        padding: 10px;
        color: var(--type-placeholder);
      }

      .set-file {
        margin: 24px;
        .set-file-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 300px;
          border: 1px dashed #305BF2;
          border-radius: 8px;

          .set-file-content-text {
            font-weight: 700;
            font-size: 12px;
            line-height: 130%;
          }

          .middle-set-file-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 12px 0;

            .middle-set-file-content-detail {
              width: 54px;
              height: 2px;
              background: var(--neutral-300);
              border-radius: 16px;
            }

            .middle-set-file-content-text {
              font-weight: 400;
              font-size: 12px;
              line-height: 130%;
              padding: 0 14px;
            }
          }

          .set-file-content-input {
            width: auto;
            height: auto;
            padding: 8px 16px;
            font-weight: 700;
            font-size: 12px;
            line-height: 130%;
            color: var(--neutral-000);
            border-radius: 8px;
            background: var(--blue-700);
            cursor: pointer;
          }

          #set-file-content-input {
            display: none;
          }
        }
      }

      .card-selected-files{
        border-radius: 8px;
        background-color: #fff;
      }

    }

    .md-content {
      margin: 0 auto;
      padding: 30px 20px 0 20px;
    }

    .all-imports {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      font-size: 0.95em;
      max-height: 30vh;

      .progress-content {
        border-radius: 8px;
        padding: 20px;
        background-color: var(--neutral-100);
        margin-bottom: 3%;
      }

      .filename {
        text-align: left;
        .xml-name {
          max-width: 90%;
        }
      }
    }
  }
}
</style>

